@import "~@ng-select/ng-select/themes/default.theme.css";
// size in pixels
$html-font-size: 16;
$html-mobile-font-size: 14;
@function rem($pxValue) {
  @return #{$pxValue / $html-font-size}rem;
}
html {
  font-size: #{$html-font-size}px !important;
}
body {
  margin: 0;
  font-size: #{$html-font-size}px !important;
  font-family: 'Open Sans', sans-serif !important;

  h1 {
    font-size: #{rem(24)};
  }

  h2 {
    font-size: #{rem(18)};
  }

  h3 {
    font-size: rem(56);
    color: var(--dark-blue)
  }
}

:root {
  --light-blue: #e4f0f4;
  --medium-blue: #a0cadb;
  --dark-blue: #00719e;
  --dark-blue-var2: #0c678e;
  --darkdarkblue: #00394F;
  --darkdarkblue2: #04394e;
  --yellow: #F9B233;
  --white: #FFFFFF;
  --grey: #E4ECF0;
  --white075: rgba(255, 255, 255, 0.75);
  --white050: rgba(255, 255, 255, 0.50);
  --white025: rgba(255, 255, 255, 0.25);
  @media (prefers-reduced-motion: no-preference) {
    scroll-behavior: smooth;
  }
}

footer li {
  padding: 0.5rem 1rem;
  border-right: 1px solid var(--white);
  color: var(--white);
}

/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';

body {
  background-color: var(--dark-blue)
}

.logo {
  max-width: 170px;
  width: 100%;
}

a:link, a:visited, a:active {
  text-decoration: none;
  color: var(--dark-blue);
}

a:focus, a:hover {
  text-decoration: underline;
  color: var(--dark-blue);
}
.link-fake {
  cursor: pointer;
  color: var(--dark-blue);
  &:hover {
    color: var(--dark-blue);
    text-decoration: underline;
  }
}


main {
  background-color: var(--white);
  box-shadow: rgba(200, 200, 200, 0.25) 0 0.0625em 0.0625em, rgba(200, 200, 200, 0.25) 0 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0 0 0 1px inset;
  border-radius: 10px;
  color: var(--darkdarkblue);
}


.l-navbar {
  height: 100%;
  padding: 0 0 0 0;
  z-index: 1;
  font-weight: 300;
}

nav {
  .nav_list {
    > a {
      &:link {
        color: var(--darkdarkblue);
        text-decoration: none;
        width: 100%;
      }

      &:visited {
        color: var(--darkdarkblue);
        text-decoration: none;
        width: 100%;
      }

      &:link.active {
        background-color: var(--white);
        border-top-left-radius: 25px;
        border-bottom-left-radius: 25px;
        z-index: 1;
        font-weight: bolder;
        span {
          color: var(--darkdarkblue) !important;
          font-weight: bold;
        }
        i {
          color: var(--yellow)
        }
      }

      &:visited.active {
        background-color: var(--white);
        border-top-left-radius: 25px;
        border-bottom-left-radius: 25px;
        z-index: 1;
        font-weight: bolder;
        span {
          color: var(--darkdarkblue) !important;
          font-weight: bold;
        }

        i {
          color: var(--yellow)
        }
      }

      &:focus {
        > span {
          text-decoration: underline;
        }

        nav {
          .nav_list {
            > a {
              &:hover {
                text-decoration: none;
              }
            }
          }
        }
      }

      &:hover {
        > span {
          text-decoration: underline;
        }
      }
    }

    hr {
      color: var(--white);
    }
  }
}

.btn-outline-primary {
  color: var(--dark-blue);
  text-shadow: none;
  border: .05rem solid var(--dark-blue);
  border-radius: 22px;
  font-weight: 300;
  padding: 8px 14px 8px 14px;

  &:hover {
    color: var(--white);
    background-color: var(--dark-blue);
    border-color: var(--dark-blue);
  }

  &:focus {
    color: var(--white);
    background-color: var(--dark-blue);
    border-color: var(--dark-blue);
  }
}

.btn-outline-secondary {
  color: var(--dark-blue);
  text-shadow: none;
  border: .05rem solid var(--dark-blue);
  border-radius: 5px;
  font-weight: 300;
  padding: 8px 18px 8px 18px;

  &:hover {
    color: var(--white);
    background-color: var(--dark-blue);
    border-color: var(--dark-blue);
  }

  &:focus {
    color: var(--white);
    background-color: var(--dark-blue);
    border-color: var(--dark-blue);
  }

  &.whitebg {
    color: var(--dark-blue);
    text-shadow: none;
    border: .05rem solid var(--dark-blue);
    border-radius: 5px;
    font-weight: 300;
    padding: 8px 18px 8px 18px;
    background-color: var(--white);

    &:hover {
      color: var(--white);
      background-color: var(--dark-blue);
      border-color: var(--dark-blue);
    }

    &:focus {
      color: var(--white);
      background-color: var(--dark-blue);
      border-color: var(--dark-blue);
    }
  }

  &.xs {
    padding: 3px 18px 3px 18px;
  }

  &.s {
    padding: 3px 18px 3px 18px;
  }

  &.sm {
    padding: 5px 18px 5px 18px;
  }
  &.white-bg {
    background-color: var(--white);
    &:hover {
      background-color: var(--dark-blue);
    }
  }
}

a.btn-secondary:link, a.btn-secondary:visited, a.btn-secondary:active, a.btn-secondary:focus {
  color: var(--white);
}

a[role=button]:link, a[role=button]:visited, a[role=button]:active, a[role=button]:focus, a[role=button]:hover {
  text-decoration: none;
}

.btn-secondary {
  color: var(--white);
  text-shadow: none;
  border: .05rem solid var(--dark-blue);
  border-radius: 5px;
  font-weight: 300;
  padding: 8px 18px 8px 18px;
  background-color: var(--dark-blue);

  &:hover {
    color: var(--dark-blue);
    background-color: transparent;
    border-color: var(--dark-blue);
  }

  &:focus {
    color: var(--white);
    background-color: var(--dark-blue);
    border-color: var(--white);
  }

  &.whitebg {
    &:hover {
      color: var(--dark-blue);
      background-color: var(--white);
      border-color: var(--dark-blue);
    }

    &:focus {
      color: var(--white);
      background-color: var(--dark-blue);
      border-color: var(--white);
    }
  }

  &.xs {
    padding: 3px 18px 3px 18px;
  }

  &.s {
    padding: 3px 18px 3px 18px;
  }

  &.sm {
    padding: 5px 18px 5px 18px;
  }
}

.transparentcard {
  z-index: 2;
  background-color: var(--white075);
  border-radius: 5px;
}


i.yiconcircle {
  border: 1px solid var(--yellow);
  border-radius: 50%;
}

i.sm-cust {
  color: var(--yellow);
  padding: 0.7rem 0.9rem;
  background-color: var(--white);
}

.fthin {
  font-weight: 100 !important;
}

.fextralight {
  font-weight: 200 !important;
}

.flight {
  font-weight: 300 !important;
}

.fregular {
  font-weight: 400 !important;
}

.fmedium {
  font-weight: 500 !important;
}

.fsemibold {
  font-weight: 600 !important;
}

.fbold {
  font-weight: 700 !important;
}

.fextrabold {
  font-weight: 800 !important;
}

.fblack {
  font-weight: 900 !important;
}

.relative-half-way-up {
  position: relative;
  top: -18px;
}
.mobile .relative-half-way-up {
  position: initial;
}
.transparentcard {
  z-index: 2;
  background-color: var(--white075);
  border-radius: 5px;
}

$colors-list: (
  "white": #ffffff,
  "yellow": var(--yellow),
  "gray": var(--grey),
  "dark-blue": var(--dark-blue),
  "dark-blue2": var(--darkdarkblue),
  "dark-blue21": var(--dark-blue-var2),
  "dark-blue22": var(--darkdarkblue2),
);

@each $key, $val in $colors-list {
  .bg-#{$key} {
    background-color: #{$val} !important;
  }

  .txt-#{$key} {
    color: #{$val} !important;
  }
}


// size in pixels
$fontsizes: (14, 16, 18, 20, 21, 22, 24, 26, 36);

@each $item in $fontsizes {
  .fs-#{$item} {
    font-size: #{rem($item)};
  }
}


.bbyellow {
  border-bottom: 1px solid var(--yellow);
}

.form-check-input:checked {
  background-color: var(--dark-blue);
}

.bg-care-and-me {
  background-position: 0 0;
  background-image: url('/assets/section-card-backgrounds/h3.png');
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 5px;
}


.mhi12 {
  position: relative;
  overflow: hidden;

  &:before {
    content: ' ';
    background-position: 0 0;
    background-image: url(/assets/section-card-backgrounds/h12.png);
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 0.5;
    border-radius: 5px;
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  > div {
    position: relative;
    z-index: 2;
  }
}

.grey_card.card {
  background-color: var(--grey) !important;
  border: 0 none !important;;
  text-align: left;
}

.ng-option-disabled {
  display: none;
}

.accordion {
  .accordion-item {
    border-width: 0;
  }

  .accordion-header .accordion-button {
    background-color: var(--darkdarkblue);
  }

  .accordion-body {
    border-width: 0;
    background-color: var(--grey);
    padding: 1rem 1.25rem
  }
}

.bshadow {
  box-shadow: 0 0 15px 1px rgba(150, 150, 150, 0.25);
}

table {
  border-radius: 4px;
  overflow: hidden;

  th {
    vertical-align: top;
  }
}

.table-dark {
  --bs-table-bg: #00394F;
  --bs-table-striped-bg: #E4ECF0;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #373b3e;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #323539;
  --bs-table-hover-color: #fff;
  color: #fff;
  border-color: #00394F;
}

.table-light {
  --bs-table-bg: rgba(0, 113, 158, 0.3);
  --bs-table-striped-bg: #ecedee;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfe0e1;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5e6e7;
  --bs-table-hover-color: #000;
  color: #fff;
  border-color: rgba(0, 113, 158, 0.3);
}

.table-striped {
  > tbody > tr:nth-of-type(#{$table-striped-order}) {
    --#{$variable-prefix}table-accent-bg: var(--#{$variable-prefix}table-striped-bg);
    color: var(--#{$variable-prefix}table-striped-color);
  }
}

//RGAA compliance css

input[type=checkbox] {
  min-width: 1.5rem;
  min-height: 1.5rem;
  border-color: var(--dark-blue) !important;
}

select {
  min-height: 44px;
  border-color: var(--dark-blue) !important;
}

.ng-select .ng-select-container {
  min-height: 44px;
}

.ng-select.ng-select-single .ng-select-container {
  height: 44px;
  border-color: var(--dark-blue) !important;
}

input[type=text], input[type=password], input[type=date], input[type=number] {
  min-height: 44px;
  border-color: var(--dark-blue) !important;
}

.is-invalid + .ng-invalid {
  border-color: #dc3545;

  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.is-invalid.custom-boostrap-invalid {
  .invalid-feedback {
    top: 1.5rem;
    display: block;
  }
}

.flatpickr-months .flatpickr-month {
  height: 44px !important;
}
.mobile {
  .w-50, .w-25 {
    width: 100% !important;
  }
}

.accordion-button span.badge  {
  pointer-events: none;
}

.invalid-feedback {
  color: #CF1732;
}

.offcanvas-body nav {
  .txt-white {
    color: var(--bs-body-color) !important;
  }
}
.active .imgPrev.menu-item {
  background-image: url("/assets/Picto_Aidants-yellow.svg");
}
.mobile .active .imgPrev.menu-item {
  background-image: url("/assets/Picto_Aidants-yellow.svg");
}
.mobile .imgPrev.menu-item {
  background-image: url("/assets/Picto_Aidants-black.svg");
  background-position-x: -4px;
  width: 28px !important;
}
nav.mobile {
  overflow: auto;
  .btn-secondary .txt-white {
    color: var(--white) !important;
  }
  .btn-secondary.txt-white {
    color: var(--white) !important;
  }
}
.imgPrev.menu-item {
  background-image: url("/assets/Picto_Aidants-white.svg");
}

.imgPrev {
  padding: 5px !important;
  width: 38px !important;
  height: 38px !important;
  content: "";
  display: inline-block;
  background-repeat: no-repeat;
  background-position: 4px 8px;
  background-size: 80%;
  background-image: url("/assets/Picto_Aidants-yellow.svg");
  &.menu-item {
    background-origin: content-box;
    background-position-x: left;
    background-position-y: top;
    background-size: contain;
    margin-right: 0 !important;
    padding: 0 !important;
    height: 32px !important;

  }
}
.custom-header-accordion {
  border-top-left-radius: var(--bs-accordion-inner-border-radius);
  border-top-right-radius: var(--bs-accordion-inner-border-radius);
  display: flex;
  background-color: var(--darkdarkblue);

  button {
    background: transparent;

    &::after {
      content: none;
    }
  }
}
